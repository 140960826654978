<template>
  <div class="home">

    <template>
      <el-table
          :data="theData.nowPageData"
          border
          style="width: 100%">
        <el-table-column
            prop="token"
            label="交易所/币种"
        >
        </el-table-column>
        <el-table-column
            prop="bianxianhuo"
            label="币安现货"
        >
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'bianxianhuo')===1" class="cell-max">
              {{ scope.row.bianxianhuo }}
            </div>
            <div v-if="checkM(scope.row,'bianxianhuo')===-1" class="cell-min">
              {{ scope.row.bianxianhuo }}
            </div>
            <div v-if="checkM(scope.row,'bianxianhuo')===0">
              {{ scope.row.bianxianhuo }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="bianyongxu"
            label="币安永续">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'bianyongxu')===1" class="cell-max">
              {{ scope.row.bianyongxu }}
            </div>
            <div v-if="checkM(scope.row,'bianyongxu')===-1" class="cell-min">
              {{ scope.row.bianyongxu }}
            </div>
            <div v-if="checkM(scope.row,'bianyongxu')===0">
              {{ scope.row.bianyongxu }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="biandangji"
            label="币安当季">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'biandangji')===1" class="cell-max">
              {{ scope.row.biandangji }}
            </div>
            <div v-if="checkM(scope.row,'biandangji')===-1" class="cell-min">
              {{ scope.row.biandangji }}
            </div>
            <div v-if="checkM(scope.row,'biandangji')===0">
              {{ scope.row.biandangji }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="bianciji"
            label="币安次季">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'bianciji')===1" class="cell-max">
              {{ scope.row.bianciji }}
            </div>
            <div v-if="checkM(scope.row,'bianciji')===-1" class="cell-min">
              {{ scope.row.bianciji}}
            </div>
            <div v-if="checkM(scope.row,'bianciji')===0">
              {{ scope.row.bianciji }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="tfxxianhuo"
            label="TFX现货">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'tfxxianhuo')===1" class="cell-max">
              {{ scope.row.tfxxianhuo }}
            </div>
            <div v-if="checkM(scope.row,'tfxxianhuo')===-1" class="cell-min">
              {{ scope.row.tfxxianhuo }}
            </div>
            <div v-if="checkM(scope.row,'tfxxianhuo')===0">
              {{ scope.row.tfxxianhuo }}
            </div>

          </template>
        </el-table-column>
        <el-table-column
            prop="tfxyongxu"
            label="TFX永续">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'tfxyongxu')===1" class="cell-max">
              {{ scope.row.tfxyongxu }}
            </div>
            <div v-if="checkM(scope.row,'tfxyongxu')===-1" class="cell-min">
              {{ scope.row.tfxyongxu }}
            </div>
            <div v-if="checkM(scope.row,'tfxyongxu')===0">
              {{ scope.row.tfxyongxu }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="tfxdangji"
            label="TFX当季">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'tfxdangji')===1" class="cell-max">
              {{ scope.row.tfxdangji }}
            </div>
            <div v-if="checkM(scope.row,'tfxdangji')===-1" class="cell-min">
              {{ scope.row.tfxdangji }}
            </div>
            <div v-if="checkM(scope.row,'tfxdangji')===0">
              {{ scope.row.tfxdangji }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="tfxciji"
            label="TFX次季">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'tfxciji')===1" class="cell-max">
              {{ scope.row.tfxciji }}
            </div>
            <div v-if="checkM(scope.row,'tfxciji')===-1" class="cell-min">
              {{ scope.row.tfxciji }}
            </div>
            <div v-if="checkM(scope.row,'tfxciji')===0">
              {{ scope.row.tfxciji }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="okxianhuo"
            label="OK现货">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'okxianhuo')===1" class="cell-max">
              {{ scope.row.okxianhuo}}
            </div>
            <div v-if="checkM(scope.row,'okxianhuo')===-1" class="cell-min">
              {{ scope.row.okxianhuo }}
            </div>
            <div v-if="checkM(scope.row,'okxianhuo')===0">
              {{ scope.row.okxianhuo}}
            </div>

          </template>
        </el-table-column>
        <el-table-column
            prop="okyongxu"
            label="OK永续">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'okyongxu')===1" class="cell-max">
              {{ scope.row.okyongxu }}
            </div>
            <div v-if="checkM(scope.row,'okyongxu')===-1" class="cell-min">
              {{ scope.row.okyongxu }}
            </div>
            <div v-if="checkM(scope.row,'okyongxu')===0">
              {{ scope.row.okyongxu }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="okdangzhou"
            label="OK当周">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'okdangzhou')===1" class="cell-max">
              {{ scope.row.okdangzhou }}
            </div>
            <div v-if="checkM(scope.row,'okdangzhou')===-1" class="cell-min">
              {{ scope.row.okdangzhou }}
            </div>
            <div v-if="checkM(scope.row,'okdangzhou')===0">
              {{ scope.row.okdangzhou }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="okcizhou"
            label="OK次周">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'okcizhou')===1" class="cell-max">
              {{ scope.row.okcizhou }}
            </div>
            <div v-if="checkM(scope.row,'okcizhou')===-1" class="cell-min">
              {{ scope.row.okcizhou }}
            </div>
            <div v-if="checkM(scope.row,'okcizhou')===0">
              {{ scope.row.okcizhou }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="okdangji"
            label="OK当季">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'okdangji')===1" class="cell-max">
              {{ scope.row.okdangji }}
            </div>
            <div v-if="checkM(scope.row,'okdangji')===-1" class="cell-min">
              {{ scope.row.okdangji }}
            </div>
            <div v-if="checkM(scope.row,'okdangji')===0">
              {{ scope.row.okdangji }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="okciji"
            label="OK次季">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'okciji')===1" class="cell-max">
              {{ scope.row.okciji }}
            </div>
            <div v-if="checkM(scope.row,'okciji')===-1" class="cell-min">
              {{ scope.row.okciji }}
            </div>
            <div v-if="checkM(scope.row,'okciji')===0">
              {{ scope.row.okciji }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="gtxianhuo"
            label="GT现货">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'gtxianhuo')===1" class="cell-max">
              {{ scope.row.gtxianhuo }}
            </div>
            <div v-if="checkM(scope.row,'gtxianhuo')===-1" class="cell-min">
              {{ scope.row.gtxianhuo }}
            </div>
            <div v-if="checkM(scope.row,'gtxianhuo')===0">
              {{ scope.row.gtxianhuo }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="gtyongxu"
            label="GT永续">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'gtyongxu')===1" class="cell-max">
              {{ scope.row.gtyongxu }}
            </div>
            <div v-if="checkM(scope.row,'gtyongxu')===-1" class="cell-min">
              {{ scope.row.gtyongxu }}
            </div>
            <div v-if="checkM(scope.row,'gtyongxu')===0">
              {{ scope.row.gtyongxu }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="mochaxianhuo"
            label="抹茶现货">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'mochaxianhuo')===1" class="cell-max">
              {{ scope.row.mochaxianhuo }}
            </div>
            <div v-if="checkM(scope.row,'mochaxianhuo')===-1" class="cell-min">
              {{ scope.row.mochaxianhuo }}
            </div>
            <div v-if="checkM(scope.row,'mochaxianhuo')===0">
              {{ scope.row.mochaxianhuo }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="mochayongxu"
            label="抹茶永续">
          <template slot-scope="scope">
            <div v-if="checkM(scope.row,'mochayongxu')===1" class="cell-max">
              {{ scope.row.mochayongxu }}
            </div>
            <div v-if="checkM(scope.row,'mochayongxu')===-1" class="cell-min">
              {{ scope.row.mochayongxu }}
            </div>
            <div v-if="checkM(scope.row,'mochayongxu')===0">
              {{ scope.row.mochayongxu }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="chajia"
            label="差价">
          <template slot-scope="scope">
            {{ scope.row.chajia + '%' }}
          </template>
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-switch
                v-model="scope.row.open"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="block">

      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="theData.pageSizes"
          :page-size="theData.pageSize"
          :current-page="theData.nowPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="theData.tableData.length">
      </el-pagination>
    </div>
  </div>
</template>
<style>
.cell-max {
  background-color: #8cfa6e;
}

.cell-min {
  background-color: #fa6e6e;
}

</style>
<script>
// @ is an alias to /src

const axios = require('axios')
export default {
  name: 'Home',
  components: {},
  mounted() {
    // localStorage.clear();
    if (localStorage.token) {
      this.theData = JSON.parse(localStorage.token)
    }
    if (localStorage.api) {
      this.theData.apiData = JSON.parse(localStorage.api).apiData
    }

    let that = this
    for (let i = 1; i <= 1000; i++) {
      clearInterval(i);
    }
    setTimeout(function () {
      // console.log(JSON.parse(localStorage.api))
      if (localStorage.api) {
        location.reload();
      }
    }, 1000*60*2)
    setInterval(function () {
      // console.log(JSON.parse(localStorage.api))
      if (localStorage.api) {
        that.theData.apiData = JSON.parse(localStorage.api).apiData
      }
    }, 500)
    setInterval(function () {
      that.getNowTableData()
    }, 500)
    that.reAllData();
    this.timeId = setInterval(function () {
      that.reAllData();
      that.reAllBiLv()
    }, 3000)
  },
  beforeDestroy() {
    // window.clearInterval(this.timeId)
  },
  data() {
    return {
      timeId: '',
      newZ:[],
      theData: {
        apiData: [
          {
            apiName: '币安-api',
            api: 'https://api.binance.com',
            apiActive: '正常',
            key: 'Iqey7jKLeTYu3iRdXpKP4Yyst2sBZooFYb4W1sPRBUOek9pGVYjvCNpEKlznDsns',
            S: 'k2hZshhg1LB0r0iugRBExGTbn10w5MCfVOeWIDKHCob0fscJ28gGucWgrk9gRVnH'
          },
          {
            apiName: 'FTX-api',
            api: 'https://ftx.com/api',
            apiActive: '正常',
            key: 'ehUG0G0W7CscGENacJTgoAbDxfLcDSXTA72P73oy',
            S: 'Qs9ywf1jQeo3cQPo6MV65ywZD0vLrYmLwvTzML2PH'
          },
          {
            apiName: 'OK-api',
            api: 'https://www.okex.com/',
            apiActive: '正常',
            key: '5c162a96-09aa-4e32-984a-8bf7842c1597',
            S: '9AED5403089E3DCE3461D21A0E66EE28'
          },
          {
            apiName: 'GT-api',
            api: 'https://api.gateio.ws/api/v4',
            apiActive: '正常',
            key: '6336357bba41f5a7b5e43a4cab07cf6e',
            S: 'k2hZshhg1LB0r0iugRBExGTbn10w5MCfVOeWIDKHCob0fscJ28gGucWgrk9gRVnH'
          },
          {
            apiName: '抹茶-api',
            api: 'https://www.mexc.com',
            apiActive: '正常',
            key: 'mx0ZJck0Ih3jxLTDgx',
            S: 'e6cf94f234dc9f9d991bd8211c67d9f702a1e57f44c982c7458501a1140381fa'
          },
        ],
        nowPage: 1,
        pageSizes: [10, 30, 50, 100],
        pageSize: 10,
        tableData: [],
        nowPageData: [],
        newTokenDemo: {
          token: '',
          bianxianhuo: '无',
          bianyongxu: '无',
          biandangji: '无',
          bianciji: '无',
          tfxxianhuo: '无',
          tfxyongxu: '无',
          tfxdangji: '无',
          tfxciji: '无',
          okxianhuo: '无',
          okyongxu: '无',
          okdangzhou: '无',
          okcizhou: '无',
          okdangji: '无',
          okciji: '无',
          gtxianhuo: '无',
          gtyongxu: '无',
          mochaxianhuo: '无',
          mochayongxu: '无',
          chajia: 0,
          open: true,
        }
      }
    }
  },
  methods: {
    reStore: function () {
      window.localStorage.token = JSON.stringify(this.theData);
    },
    checkNewZ:function (tokenName,link){
      let inTable=false;
      for(let i=0;i<this.theData.tableData.length;i++){
        if(this.theData.tableData[i].token===tokenName){
          inTable=true
        }
      }
      if(!inTable){
        let theNewTokens=[]
        if (window.localStorage.newTokens) {
          theNewTokens = JSON.parse(window.localStorage.newTokens)
        }
        theNewTokens.push({tokenName,link})
        console.log(theNewTokens)
        window.localStorage.newTokens = JSON.stringify(theNewTokens);
      }
    },
    checkM(theRow, prop) {
      if(theRow[prop]==='无'){
        return 0
      }
      if ((theRow.bianxianhuo==='无'||theRow[prop] >= theRow.bianxianhuo)
          && (theRow.bianyongxu==='无'||theRow[prop] >= theRow.bianyongxu)
          && (theRow.biandangji==='无'||theRow[prop] >= theRow.biandangji)
          && (theRow.bianciji==='无'||theRow[prop] >= theRow.bianciji)
          && (theRow.tfxxianhuo==='无'||theRow[prop] >= theRow.tfxxianhuo )
          && (theRow.tfxyongxu==='无'||theRow[prop] >= theRow.tfxyongxu )
          && (theRow.tfxdangji==='无'||theRow[prop] >= theRow.tfxdangji )
          && (theRow.tfxciji==='无'||theRow[prop] >= theRow.tfxciji)
          && (theRow.okxianhuo==='无'||theRow[prop] >= theRow.okxianhuo )
          && (theRow.okyongxu==='无'||theRow[prop] >= theRow.okyongxu )
          && (theRow.okdangzhou==='无'||theRow[prop] >= theRow.okdangzhou )
          && (theRow.okcizhou==='无'||theRow[prop] >= theRow.okcizhou)
          && (theRow.okdangji==='无'||theRow[prop] >= theRow.okdangji )
          && (theRow.okciji==='无'||theRow[prop] >= theRow.okciji )
          && (theRow.gtxianhuo==='无'||theRow[prop] >= theRow.gtxianhuo )
          && (theRow.gtyongxu==='无'||theRow[prop] >= theRow.gtyongxu)
          && (theRow.mochaxianhuo==='无'||theRow[prop] >= theRow.mochaxianhuo )
          && (theRow.mochayongxu==='无'||theRow[prop] >= theRow.mochayongxu)) {
        return 1
      }
      if ((theRow.bianxianhuo==='无'||theRow[prop] <= theRow.bianxianhuo )
          && (theRow.bianyongxu==='无'||theRow[prop] <= theRow.bianyongxu )
          && (theRow.biandangji==='无'||theRow[prop] <= theRow.biandangji )
          && (theRow.bianciji==='无'||theRow[prop] <= theRow.bianciji)
          && (theRow.tfxxianhuo==='无'||theRow[prop] <= theRow.tfxxianhuo )
          && (theRow.tfxyongxu==='无'||theRow[prop] <= theRow.tfxyongxu )
          && (theRow.tfxdangji==='无'||theRow[prop] <= theRow.tfxdangji )
          && (theRow.tfxciji==='无'||theRow[prop] <= theRow.tfxciji)
          && (theRow.okxianhuo==='无'||theRow[prop] <= theRow.okxianhuo )
          && (theRow.okyongxu==='无'||theRow[prop] <= theRow.okyongxu )
          && (theRow.okdangzhou==='无'||theRow[prop] <= theRow.okdangzhou )
          && (theRow.okcizhou==='无'||theRow[prop] <= theRow.okcizhou)
          && (theRow.okdangji==='无'||theRow[prop] <= theRow.okdangji )
          && (theRow.okciji==='无'||theRow[prop] <= theRow.okciji )
          && (theRow.gtxianhuo==='无'||theRow[prop] <= theRow.gtxianhuo )
          && (theRow.gtyongxu==='无'||theRow[prop] <= theRow.gtyongxu)
          && (theRow.mochaxianhuo==='无'||theRow[prop] <= theRow.mochaxianhuo )
          && (theRow.mochayongxu==='无'||theRow[prop] <= theRow.mochayongxu)) {
        return -1
      }
      return 0

    },
    reAllData: async function () {
      console.log(window.localStorage)
      this.reBianNowData();
      this.reStore()
      this.reBianYongxuData();
      this.reStore()
      this.reBianJiduData();
      this.reStore()
      this.reTfxData();
      this.reStore()
      this.reOkData();
      this.reStore()
      this.reOkYongxuData();
      this.reStore()
      this.reOkQihuoData();
      this.reStore()
      this.reGtData();
      this.reStore()
      this.reGtYongxuData();
      this.reStore()
      this.reMoChaData();
      this.reStore()
      this.reMoChaYongXuData();
      this.reStore()
      // await this.reBianNowData();
      // await this.reBianYongxuData();
      // await this.reBianJiduData();
      // await this.reTfxData();
      // await this.reOkData();
      // await this.reOkYongxuData();
      // await this.reOkQihuoData();
      // await this.reGtData();
      // await this.reGtYongxuData();
      // await this.reMoChaData();
      // await this.reMoChaYongXuData();
    },
    checkStr1: function (str) {
      if (str.search('BEAR') !== -1) {
        return false
      }
      if (str.search('BULL') !== -1) {
        return false
      }
      if (str.search('3L') !== -1) {
        return false
      }
      if (str.search('3S') !== -1) {
        return false
      }
      if (str.search('5L') !== -1) {
        return false
      }
      if (str.search('5S') !== -1) {
        return false
      }
      return true

    },
    reBianNowData: async function () {
      let theApi = this.theData.apiData[0].api
      let response = await axios.get(theApi + '/api/v3/ticker/price'
      )
          .catch(function (error) {
            console.log(error);
          });
      if (response.status === 200) {
        let reTokens = []
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].symbol.substr(-4) === 'USDT' && this.checkStr1(response.data[i].symbol)) {
            response.data[i].symbol = response.data[i].symbol.substring(0, response.data[i].symbol.length - 4)
            reTokens.push(response.data[i])
            let has = 0
            for (let y = 0; y < this.theData.tableData.length; y++) {
              if (this.theData.tableData[y].token === response.data[i].symbol) {
                has = 1
                this.theData.tableData[y].bianxianhuo = response.data[i].price
              }
            }
            if (has === 0) {
              let newToken = JSON.parse(JSON.stringify(this.theData.newTokenDemo))
              newToken.token = response.data[i].symbol
              newToken.bianxianhuo = response.data[i].price
              this.checkNewZ(newToken.token,'bianxianhuo')
              this.theData.tableData.push(newToken)
            }
          }
        }
      } else {
        console.log(response)
      }
    },
    reBianYongxuData: async function () {
      let theApi = 'https://fapi.binance.com'
      let response = await axios.get(theApi + '/fapi/v1/ticker/price'
      )
          .catch(function (error) {
            console.log(error);
          });
      if (response.status === 200) {
        let reTokens = []
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].symbol.substr(-4) === 'USDT' && this.checkStr1(response.data[i].symbol)) {
            response.data[i].symbol = response.data[i].symbol.substring(0, response.data[i].symbol.length - 4)
            reTokens.push(response.data[i])
            let has = 0
            for (let y = 0; y < this.theData.tableData.length; y++) {
              if (this.theData.tableData[y].token === response.data[i].symbol) {
                has = 1
                this.theData.tableData[y].bianyongxu = response.data[i].price
              }
            }
            if (has === 0) {
              let newToken = JSON.parse(JSON.stringify(this.theData.newTokenDemo))
              newToken.token = response.data[i].symbol
              newToken.bianyongxu = response.data[i].price
              this.checkNewZ(newToken.token,'bianyongxu')
              this.theData.tableData.push(newToken)
            }
          }
        }
      } else {
        console.log(response)
      }
    },
    reBianJiduData: async function () {
      let theApi = 'https://dapi.binance.com'
      let response = await axios.get(theApi + '/dapi/v1/ticker/price'
      )
          .catch(function (error) {
            console.log(error);
          });
      if (response.status === 200) {
        let reTokens = []
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].ps.substr(-3) === 'USD' && this.checkStr1(response.data[i].ps)) {
            response.data[i].ps = response.data[i].ps.substring(0, response.data[i].ps.length - 3)
            reTokens.push(response.data[i])
            let has = 0
            for (let y = 0; y < this.theData.tableData.length; y++) {
              if (this.theData.tableData[y].token === response.data[i].ps) {
                has = 1
                let nowTimeST = parseInt((new Date()).getTime())
                let theTime = response.data[i].symbol
                let theTimeST = new Date('20' + theTime.substr(theTime.length - 6, 2) + '-' + theTime.substr(theTime.length - 4, 2) + '-' + theTime.substr(theTime.length - 2, 2)).getTime()
                if (!isNaN(theTimeST)) {
                  if ((nowTimeST + 1000 * 60 * 60 * 24 * 90) > theTimeST) {
                    this.theData.tableData[y].biandangji = response.data[i].price
                  }
                  if ((nowTimeST + 1000 * 60 * 60 * 24 * 90) < theTimeST && (nowTimeST + 1000 * 60 * 60 * 24 * 200) > theTimeST) {
                    this.theData.tableData[y].bianciji = response.data[i].price
                  }
                }

              }
            }
            if (has === 0) {
              let newToken = JSON.parse(JSON.stringify(this.theData.newTokenDemo))
              newToken.token = response.data[i].ps
              let nowTimeST = (new Date()).getTime()
              let theTime = response.data[i].symbol
              let theTimeST = new Date('20' + theTime.substr(theTime.length - 6, 2) + '-' + theTime.substr(theTime.length - 4, 2) + '-' + theTime.substr(theTime.length - 2, 2)).getTime()
              if (!isNaN(theTimeST)) {
                if ((nowTimeST + 1000 * 60 * 60 * 24 * 90) > theTimeST) {
                  newToken.biandangji = response.data[i].price
                  this.checkNewZ(newToken.token,'biandangji')
                  this.theData.tableData.push(newToken)
                }
                if ((nowTimeST + 1000 * 60 * 60 * 24 * 90) < theTimeST && (nowTimeST + 1000 * 60 * 60 * 24 * 200) > theTimeST) {
                  newToken.bianciji = response.data[i].price
                  this.checkNewZ(newToken.token,'bianciji')
                  this.theData.tableData.push(newToken)

                }
              }
            }
          }
        }
      } else {
        console.log(response)
      }

    },
    reTfxData: async function () {
      // let theApi = '/ftx'
      let theApi = 'https://ftx.com/api'
      let response = await axios.get(theApi + '/markets'
      )
          .catch(function (error) {
            console.log(error);
          });
      if (response.status === 200) {
        let reTokens = []
        for (let i = 0; i < response.data.result.length; i++) {
          // console.log(response.data.result[i])
          if (response.data.result[i].name.substr(-3) === 'USD' && this.checkStr1(response.data.result[i].name)) {
            response.data.result[i].symbol = response.data.result[i].baseCurrency
            reTokens.push(response.data.result[i])
            let has = 0
            for (let y = 0; y < this.theData.tableData.length; y++) {
              if (this.theData.tableData[y].token === response.data.result[i].baseCurrency) {
                has = 1
                this.theData.tableData[y].tfxxianhuo = response.data.result[i].last
              }
            }
            if (has === 0) {
              let newToken = JSON.parse(JSON.stringify(this.theData.newTokenDemo))
              newToken.token = response.data.result[i].baseCurrency
              newToken.tfxxianhuo = response.data.result[i].last
              this.checkNewZ(newToken.token,'tfxxianhuo')
              this.theData.tableData.push(newToken)

            }
          }
          if (response.data.result[i].name.substr(-4) === 'PERP' && this.checkStr1(response.data.result[i].name)) {
            response.data.result[i].symbol = response.data.result[i].underlying
            reTokens.push(response.data.result[i])
            let has = 0
            for (let y = 0; y < this.theData.tableData.length; y++) {
              if (this.theData.tableData[y].token === response.data.result[i].underlying) {
                has = 1
                this.theData.tableData[y].tfxyongxu = response.data.result[i].last
              }
            }
            if (has === 0) {
              let newToken = JSON.parse(JSON.stringify(this.theData.newTokenDemo))
              newToken.token = response.data.result[i].underlying
              newToken.tfxyongxu = response.data.result[i].last
              this.checkNewZ(newToken.token,'tfxyongxu')
              this.theData.tableData.push(newToken)

            }
          }
          let theTimeBack = response.data.result[i].name.split('-', 2)[1]
          if (theTimeBack > 0 && this.checkStr1(response.data.result[i].name)) {
            response.data.result[i].symbol = response.data.result[i].underlying
            reTokens.push(response.data.result[i])
            let has = 0
            for (let y = 0; y < this.theData.tableData.length; y++) {
              if (this.theData.tableData[y].token === response.data.result[i].underlying) {
                has = 1
                let nowTimeST = parseInt((new Date()).getTime())
                let theTime = theTimeBack
                let theTimeYear = new Date().getFullYear()
                if (theTime.substr(0, 2) < new Date().getMonth() + 1) {
                  theTimeYear++
                }
                let theTimeST = new Date(theTimeYear + '-' + theTime.substr(0, 2) + '-' + theTime.substr(2, 2)).getTime()
                if ((nowTimeST + 1000 * 60 * 60 * 24 * 90) > theTimeST) {
                  this.theData.tableData[y].tfxdangji = response.data.result[i].last
                }
                if ((nowTimeST + 1000 * 60 * 60 * 24 * 90) < theTimeST && (nowTimeST + 1000 * 60 * 60 * 24 * 200) > theTimeST) {
                  this.theData.tableData[y].tfxciji = response.data.result[i].last
                }
              }
            }
            if (has === 0) {

              let newToken = JSON.parse(JSON.stringify(this.theData.newTokenDemo))
              newToken.token = response.data.result[i].symbol

              let nowTimeST = parseInt((new Date()).getTime())
              let theTime = theTimeBack
              let theTimeYear = new Date().getFullYear()
              if (theTime.substr(0, 2) < new Date().getMonth() + 1) {
                theTimeYear++
              }
              let theTimeST = new Date(theTimeYear + '-' + theTime.substr(0, 2) + '-' + theTime.substr(2, 2)).getTime()
              if ((nowTimeST + 1000 * 60 * 60 * 24 * 90) > theTimeST) {
                newToken.tfxdangji = response.data.result[i].price
                this.checkNewZ(newToken.token,'tfxdangji')
                this.theData.tableData.push(newToken)

              }
              if ((nowTimeST + 1000 * 60 * 60 * 24 * 90) < theTimeST && (nowTimeST + 1000 * 60 * 60 * 24 * 200) > theTimeST) {
                newToken.tfxciji = response.data.result[i].price
                this.checkNewZ(newToken.token,'tfxciji')
                this.theData.tableData.push(newToken)

              }
            }
          }
        }
      } else {
        console.log(response)
      }
    },
    reOkData: async function () {
      // let theApi = '/ok'
      let theApi = 'https://www.okex.com/'
      let response = await axios.get(theApi + '/api/v5/market/tickers?instType=SPOT'
      )

          .catch(function (error) {
            console.log(error);
          });
      if (response.status === 200) {
        let reTokens = []
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].instId.substr(-4) === 'USDT' && this.checkStr1(response.data.data[i].instId)) {
            response.data.data[i].symbol = response.data.data[i].instId.substring(0, response.data.data[i].instId.length - 5)
            reTokens.push(response.data.data[i])
            let has = 0
            for (let y = 0; y < this.theData.tableData.length; y++) {
              if (this.theData.tableData[y].token === response.data.data[i].symbol) {
                has = 1
                this.theData.tableData[y].okxianhuo = response.data.data[i].last
              }
            }
            if (has === 0) {
              let newToken = JSON.parse(JSON.stringify(this.theData.newTokenDemo))
              newToken.token = response.data.data[i].symbol
              newToken.okxianhuo = response.data.data[i].last
              this.checkNewZ(newToken.token,'okxianhuo')
              this.theData.tableData.push(newToken)

            }
          }
        }
      } else {
        console.log(response)
      }
    },
    reOkYongxuData: async function () {
      // let theApi = '/ok'
      let theApi = 'https://www.okex.com/'
      let response = await axios.get(theApi + '/api/v5/market/tickers?instType=SWAP'
      )

          .catch(function (error) {
            console.log(error);
          });
      // console.log(response)
      // if (response.status === 200) {
      //   return
      // }
      if (response.status === 200) {
        let reTokens = []
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].instId.substr(-9) === 'USDT-SWAP' && this.checkStr1(response.data.data[i].instId)) {
            response.data.data[i].symbol = response.data.data[i].instId.substring(0, response.data.data[i].instId.length - 10)
            reTokens.push(response.data.data[i])
            let has = 0
            for (let y = 0; y < this.theData.tableData.length; y++) {
              if (this.theData.tableData[y].token === response.data.data[i].symbol) {
                has = 1
                this.theData.tableData[y].okyongxu = response.data.data[i].last
              }
            }
            if (has === 0) {
              let newToken = JSON.parse(JSON.stringify(this.theData.newTokenDemo))
              newToken.token = response.data.data[i].symbol
              newToken.okyongxu = response.data.data[i].last
              this.checkNewZ(newToken.token,'okyongxu')
              this.theData.tableData.push(newToken)

            }
          }
        }
      } else {
        console.log(response)
      }
    },
    reOkQihuoData: async function () {
      // let theApi = '/ok'
      let theApi = 'https://www.okex.com/'
      let response = await axios.get(theApi + '/api/v5/market/tickers?instType=FUTURES'
      )

          .catch(function (error) {
            console.log(error);
          });
      // if (response.status === 200) {
      //   return
      // }
      if (response.status === 200) {
        let reTokens = []
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].instId.split('-', 3)[1] === 'USDT' && this.checkStr1(response.data.data[i].instId)) {
            response.data.data[i].symbol = response.data.data[i].instId.substring(0, response.data.data[i].instId.length - 12)
            reTokens.push(response.data.data[i])
            let has = 0
            for (let y = 0; y < this.theData.tableData.length; y++) {
              if (this.theData.tableData[y].token === response.data.data[i].symbol) {
                has = 1
                this.theData.tableData[y].okyongxu = response.data.data[i].last
                let nowTimeST = parseInt((new Date()).getTime())
                let theTime = response.data.data[i].instId
                let theTimeST = new Date('20' + theTime.substr(theTime.length - 6, 2) + '-' + theTime.substr(theTime.length - 4, 2) + '-' + theTime.substr(theTime.length - 2, 2)).getTime()
                if (!isNaN(theTimeST)) {
                  if ((nowTimeST + 1000 * 60 * 60 * 24 * 7) > theTimeST) {
                    this.theData.tableData[y].okdangzhou = response.data.data[i].last
                  }
                  if ((nowTimeST + 1000 * 60 * 60 * 24 * 7) < theTimeST && (nowTimeST + 1000 * 60 * 60 * 24 * 14) > theTimeST) {
                    this.theData.tableData[y].okcizhou = response.data.data[i].last
                  }
                  if ((nowTimeST + 1000 * 60 * 60 * 24 * 14) > theTimeST && (nowTimeST + 1000 * 60 * 60 * 24 * 90) > theTimeST) {
                    this.theData.tableData[y].okdangji = response.data.data[i].last
                  }
                  if ((nowTimeST + 1000 * 60 * 60 * 24 * 90) < theTimeST && (nowTimeST + 1000 * 60 * 60 * 24 * 200) > theTimeST) {
                    this.theData.tableData[y].okciji = response.data.data[i].last
                  }
                }
              }
            }
            if (has === 0) {
              let newToken = JSON.parse(JSON.stringify(this.theData.newTokenDemo))
              newToken.token = response.data.data[i].symbol
              newToken.okyongxu = response.data.data[i].last
              let nowTimeST = parseInt((new Date()).getTime())
              let theTime = response.data.data[i].instId
              let theTimeST = new Date('20' + theTime.substr(theTime.length - 6, 2) + '-' + theTime.substr(theTime.length - 4, 2) + '-' + theTime.substr(theTime.length - 2, 2)).getTime()
              if (!isNaN(theTimeST)) {
                if ((nowTimeST + 1000 * 60 * 60 * 24 * 7) > theTimeST) {
                  newToken.okyongxu = response.data.data[i].last
                  this.checkNewZ(newToken.token,'okyongxu')
                  this.theData.tableData.push(newToken)

                }
                if ((nowTimeST + 1000 * 60 * 60 * 24 * 7) < theTimeST && (nowTimeST + 1000 * 60 * 60 * 24 * 14) > theTimeST) {
                  newToken.okcizhou = response.data.data[i].last
                  this.checkNewZ(newToken.token,'okcizhou')
                  this.theData.tableData.push(newToken)

                }
                if ((nowTimeST + 1000 * 60 * 60 * 24 * 14) > theTimeST && (nowTimeST + 1000 * 60 * 60 * 24 * 90) > theTimeST) {
                  newToken.okdangji = response.data.data[i].last
                  this.checkNewZ(newToken.token,'okdangji')
                  this.theData.tableData.push(newToken)

                }
                if ((nowTimeST + 1000 * 60 * 60 * 24 * 90) < theTimeST && (nowTimeST + 1000 * 60 * 60 * 24 * 200) > theTimeST) {
                  newToken.okciji = response.data.data[i].last
                  this.checkNewZ(newToken.token,'okciji')
                  this.theData.tableData.push(newToken)

                }
              }


            }
          }
        }
      } else {
        console.log(response)
      }
    },
    reGtData: async function () {
      // let theApi = '/gt'
      let theApi = 'https://api.gateio.ws/api/v4'
      let response = await axios.get(theApi + '/spot/tickers'
      )
          .catch(function (error) {
            console.log(error);
          });
      if (response.status === 200) {
        let reTokens = []
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].currency_pair.substr(-4) === 'USDT' && this.checkStr1(response.data[i].currency_pair)) {
            response.data[i].symbol = response.data[i].currency_pair.substring(0, response.data[i].currency_pair.length - 5)
            reTokens.push(response.data[i])
            let has = 0
            for (let y = 0; y < this.theData.tableData.length; y++) {
              if (this.theData.tableData[y].token === response.data[i].symbol) {
                has = 1
                this.theData.tableData[y].gtxianhuo = response.data[i].last
              }
            }
            if (has === 0) {
              let newToken = JSON.parse(JSON.stringify(this.theData.newTokenDemo))
              newToken.token = response.data[i].symbol
              newToken.gtxianhuo = response.data[i].last
              this.checkNewZ(newToken.token,'gtxianhuo')
              this.theData.tableData.push(newToken)

            }
          }
        }
      } else {
        console.log(response)
      }
    },
    reGtYongxuData: async function () {
      // let theApi = '/gt'
      let theApi = 'https://api.gateio.ws/api/v4'
      let response = await axios.get(theApi + '/futures/usdt/contracts'
      )
          .catch(function (error) {
            console.log(error);
          });
      if (response.status === 200) {
        let reTokens = []
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].name.substr(-4) === 'USDT' && this.checkStr1(response.data[i].name)) {
            response.data[i].symbol = response.data[i].name.substring(0, response.data[i].name.length - 5)
            reTokens.push(response.data[i])
            let has = 0
            for (let y = 0; y < this.theData.tableData.length; y++) {
              if (this.theData.tableData[y].token === response.data[i].symbol) {
                has = 1
                this.theData.tableData[y].gtyongxu = response.data[i].last_price
              }
            }
            if (has === 0) {
              let newToken = JSON.parse(JSON.stringify(this.theData.newTokenDemo))
              newToken.token = response.data[i].symbol
              newToken.gtyongxu = response.data[i].last_price
              this.checkNewZ(newToken.token,'gtyongxu')
              this.theData.tableData.push(newToken)

            }
          }
        }
      } else {
        console.log(response)
      }
    },
    reMoChaData: async function () {
      // let theApi = '/mexc'
      let theApi = 'https://www.mexc.com'
      let response = await axios.get(theApi + '/open/api/v2/market/ticker'
      )
          .catch(function (error) {
            console.log(error);
          });
      // console.log(response)
      // if (response.status === 200) {
      //   return
      // }
      if (response.status === 200) {
        let reTokens = []
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].symbol.substr(-4) === 'USDT' && this.checkStr1(response.data.data[i].symbol)) {
            response.data.data[i].symbol = response.data.data[i].symbol.substring(0, response.data.data[i].symbol.length - 5)
            reTokens.push(response.data.data[i])
            let has = 0
            for (let y = 0; y < this.theData.tableData.length; y++) {
              if (this.theData.tableData[y].token === response.data.data[i].symbol) {
                has = 1
                this.theData.tableData[y].mochaxianhuo = response.data.data[i].last
              }
            }
            if (has === 0) {
              let newToken = JSON.parse(JSON.stringify(this.theData.newTokenDemo))
              newToken.token = response.data.data[i].symbol
              newToken.mochaxianhuo = response.data.data[i].last
              this.checkNewZ(newToken.token,'mochaxianhuo')
              this.theData.tableData.push(newToken)

            }
          }
        }
      } else {
        console.log(response)
      }
    },
    reMoChaYongXuData: async function () {
      // let theApi = '/mexc2'
      let theApi = 'https://contract.mexc.com/'
      let response = await axios.get(theApi + '/api/v1/contract/ticker'
      )
          .catch(function (error) {
            console.log(error);
          });
      // console.log(response)
      // if (response.status === 200) {
      //   return
      // }
      if (response.status === 200) {
        let reTokens = []
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].symbol.substr(-4) === 'USDT' && this.checkStr1(response.data.data[i].symbol)) {
            response.data.data[i].symbol = response.data.data[i].symbol.substring(0, response.data.data[i].symbol.length - 5)
            reTokens.push(response.data.data[i])
            let has = 0
            for (let y = 0; y < this.theData.tableData.length; y++) {
              if (this.theData.tableData[y].token === response.data.data[i].symbol) {
                has = 1
                this.theData.tableData[y].mochayongxu = response.data.data[i].lastPrice
              }
            }
            if (has === 0) {
              let newToken = JSON.parse(JSON.stringify(this.theData.newTokenDemo))
              newToken.token = response.data.data[i].symbol
              newToken.mochayongxu = response.data.data[i].lastPrice
              this.checkNewZ(newToken.token,'mochayongxu')
              this.theData.tableData.push(newToken)

            }
          }
        }
      } else {
        console.log(response)
      }
    },
    getNowTableData: function () {
      let theNew = []
      for (let i = 0; i < this.theData.pageSize; i++) {
        if (this.theData.tableData[this.theData.pageSize * (this.theData.nowPage - 1) + i]) {
          theNew.push(this.theData.tableData[this.theData.pageSize * (this.theData.nowPage - 1) + i])
        }
      }
      this.theData.nowPageData = theNew
    },
    reAllBiLv: function () {
      for (let i = 0; i < this.theData.tableData.length; i++) {
        let theMax = Math.max(this.theData.tableData[i].bianxianhuo === '无' ? 0 : this.theData.tableData[i].bianxianhuo,
            this.theData.tableData[i].bianyongxu === '无' ? 0 : this.theData.tableData[i].bianyongxu,
            this.theData.tableData[i].biandangji === '无' ? 0 : this.theData.tableData[i].biandangji,
            this.theData.tableData[i].bianciji === '无' ? 0 : this.theData.tableData[i].bianciji,
            this.theData.tableData[i].tfxxianhuo === '无' ? 0 : this.theData.tableData[i].tfxxianhuo,
            this.theData.tableData[i].tfxyongxu === '无' ? 0 : this.theData.tableData[i].tfxyongxu,
            this.theData.tableData[i].tfxdangji === '无' ? 0 : this.theData.tableData[i].tfxdangji,
            this.theData.tableData[i].tfxciji === '无' ? 0 : this.theData.tableData[i].tfxciji,
            this.theData.tableData[i].okxianhuo === '无' ? 0 : this.theData.tableData[i].okxianhuo,
            this.theData.tableData[i].okyongxu === '无' ? 0 : this.theData.tableData[i].okyongxu,
            this.theData.tableData[i].okdangzhou === '无' ? 0 : this.theData.tableData[i].okdangzhou,
            this.theData.tableData[i].okcizhou === '无' ? 0 : this.theData.tableData[i].okcizhou,
            this.theData.tableData[i].okdangji === '无' ? 0 : this.theData.tableData[i].okdangji,
            this.theData.tableData[i].okciji === '无' ? 0 : this.theData.tableData[i].okciji,
            this.theData.tableData[i].gtxianhuo === '无' ? 0 : this.theData.tableData[i].gtxianhuo,
            this.theData.tableData[i].gtyongxu === '无' ? 0 : this.theData.tableData[i].gtyongxu,
            this.theData.tableData[i].mochaxianhuo === '无' ? 0 : this.theData.tableData[i].mochaxianhuo,
            this.theData.tableData[i].mochayongxu === '无' ? 0 : this.theData.tableData[i].mochayongxu)
        let theMin = Math.min(this.theData.tableData[i].bianxianhuo === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].bianxianhuo,
            this.theData.tableData[i].bianyongxu === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].bianyongxu,
            this.theData.tableData[i].biandangji === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].biandangji,
            this.theData.tableData[i].bianciji === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].bianciji,
            this.theData.tableData[i].tfxxianhuo === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].tfxxianhuo,
            this.theData.tableData[i].tfxyongxu === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].tfxyongxu,
            this.theData.tableData[i].tfxdangji === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].tfxdangji,
            this.theData.tableData[i].tfxciji === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].tfxciji,
            this.theData.tableData[i].okxianhuo === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].okxianhuo,
            this.theData.tableData[i].okyongxu === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].okyongxu,
            this.theData.tableData[i].okdangzhou === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].okdangzhou,
            this.theData.tableData[i].okcizhou === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].okcizhou,
            this.theData.tableData[i].okdangji === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].okdangji,
            this.theData.tableData[i].okciji === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].okciji,
            this.theData.tableData[i].gtxianhuo === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].gtxianhuo,
            this.theData.tableData[i].gtyongxu === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].gtyongxu,
            this.theData.tableData[i].mochaxianhuo === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].mochaxianhuo,
            this.theData.tableData[i].mochayongxu === '无' ? 10000000000000000000000000000 : this.theData.tableData[i].mochayongxu)
        this.theData.tableData[i].chajia = (((theMax - theMin) / theMax) * 100).toFixed(2)
      }
      this.theData.tableData.sort(function (a, b) {
        var value1 = a.chajia;
        var value2 = b.chajia;
        if (a.open === true && b.open !== true) {
          return -1000000000000000000000000000000000000;
        }
        if (a.open !== true && b.open === true) {
          return 10000000000000000000000000000000000000;
        }
        return value2 - value1;
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.theData.pageSize = val
      this.getNowTableData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.theData.nowPage = val
      this.getNowTableData();
    }
  },
}
</script>
